<<template>
  <div class="twipsy right" v-show="visible" :style="tipStyle">
      <div class="twipsy-arrow"></div>
      <div class="twipsy-inner">{{title}}</div>
  </div>
</template>

<script>
export default {
    components:{},
    props:{
        title: String,
        visible: {
            type: Boolean,
            default: false
        },
        left: Number,
        top: Number
    },
    data(){
        return {

        }
    },
    watch:{},
    computed:{
        tipStyle(){
            let styleobj = {
                top: this.top +'px',
                left: (this.left + 10)+'px'
            };
            return styleobj;
        }
    },
    methods:{

    },
    created(){},
    mounted(){}
}
</script>
<style scoped>
.twipsy {
    display: block;
    position: absolute;
    visibility: visible;
    max-width: 300px;
    min-width: 100px;
    padding: 5px;
    font-size: 11px;
    z-index: 1000;
    opacity: 0.8;
    -khtml-opacity: 0.8;
    -moz-opacity: 0.8;
    filter: alpha(opacity=80);
}
.twipsy.left .twipsy-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #000000;
}
.twipsy.right .twipsy-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #000000;
}
.twipsy-inner {
    padding: 3px 8px;
    background-color: #000000;
    color: white;
    text-align: center;
    max-width: 300px;
    text-decoration: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.twipsy-arrow {
    position: absolute;
    width: 0;
    height: 0;
}
</style>