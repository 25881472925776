<template>
    <div class="planning-content-box">
        <div class="item" v-for="(item, index) in tableData" :key="index">
            <div class="item-left">
                <div class="item-left-year">
                    <span>{{ item.year }}年</span>
                    <span></span>
                </div>
                <div></div>
            </div>
            <div class="item-right">
                <div v-for="(i, inx) in item.changeList" :class="active_cur_x == index&&active_cur_y == inx?'active_cur':''" :key="inx"
                    @click="showTableInfo(i,index,inx)">{{ i.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
	mapState
} from 'vuex';
export default {
    props: {
        tableData: [],
        clear:Boolean
    },
    data(){
        return{
            active_cur_x:-1,
            active_cur_y:-1
        }       
    },
    watch:{
        clear:function(newVal,oldVal){
            this.active_cur_x = -1
            this.active_cur_y = -1
        },
        menu_active(newVal,oldVal){
            this.active_cur_x = -1
            this.active_cur_y = -1
        }
    },
    computed: {
		...mapState(['menu_active'])
	},
    methods:{
        showTableInfo:function(item,index,inx){
            this.active_cur_x = index
            this.active_cur_y = inx
            this.$emit('showTableInfo',item,inx)
        }
    }
}
</script>

<style lang="scss">
.planning-content-box::-webkit-scrollbar {
    width: .04rem;
}

.planning-content-box::-webkit-scrollbar-thumb {
    background-color: #ddd;
    width: .04rem;
    border-radius: .05rem;
}
.planning-content-box {
    font-size: .14rem;
    margin-top: .35rem;
    color: #fff;
    max-height: 6.4rem;
    overflow: auto;
    padding-right: .1rem;

    .item {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        .item-left {
            width: .8rem;

            .item-left-year {
                position: relative;

                span:first-child {
                    color: #fff;
                    font-size: .16rem;
                    line-height: .2rem;
                }

                span:last-child {
                    display: inline-block;
                    width: .12rem;
                    height: .12rem;
                    border-radius: 50%;
                    border: .05rem solid #554b20;
                    background-color: #ffc748;
                    position: absolute;
                    top: 0;
                    right: -0.11rem;
                }
            }

            div:last-child {
                height: calc(100% - .20rem);
                box-sizing: border-box;
                border-right: #ffc748 1px solid;
            }
        }

        .item-right {
            flex: 1;
            cursor: pointer;
            div {
                margin-bottom: .35rem;
                margin-left: .3rem;
                background-color: #12233ca1;
                box-shadow: inset 0 0 .08rem .03rem #0d385f;
                border: solid 1px #055999;
                line-height: .4rem;
                padding: 0 .2rem;
                
            }
            .active_cur,
            div:hover{
                box-shadow: inset 0 0 .08rem .05rem #11a2f6;
                background-color: #12233c;
            }
        }
    }
}
.planning-content-box>.item:last-child>.item-left>div:last-child{
    border: none;
    height: calc(100% - .75rem);
}
.planning-content-box>.item:first-child>.item-left>div:last-child{
    border-right: #ffc748 1px solid;
}
</style>