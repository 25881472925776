<!-- 规划导览 -->
<template>
	<div>
		<div class="page_temp page_temp_left" @click="messageActive = 0">
			<div class="contentBox">
				<item-title name="自然风貌" english="natural features" />
				<lineBorderBox type='bottom' />
				<div style="padding: .1rem .15rem;">
					<div class="img">
						<lineBorderBox />
						<span style="height: 2.16rem;" v-html="videoSource"></span>
					</div>
					<div style="display: flex;justify-content: space-between;align-items: center;">
						<div class="info_title">
							<div class="info_dot"><span></span></div>
							<span>区域简介</span>
						</div>
						<img @click="toggleQyjt" style="height: 0.2rem;cursor: pointer;" src="../assets/new_img/quwei.png" alt="">
					</div>
					<div class="cls-button-name scrollbar">
					    {{ v_cun_jyjs }}
					</div>
				</div>
			</div>
			<div class="contentBox" style="margin-top: 0.2rem;padding-bottom: 0.2rem;">
				<item-title name="乡村概况" english="village general situation" />
				<lineBorderBox type='bottom' />
				<div class="dataList-box">
					<div class="dataList-item" v-for="(item, index) in v_info_list" :key="index">
						<img :src="item.icon" alt="">
						<div class="item-info">
							<span class="type">{{ item.name }}</span>
							<div>
								<span>{{ item.value=='0%'?'--':item.value }}</span>
								<span v-show="item.unit">（{{item.unit}}）</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="contentBox" style="margin-top: 0.2rem;padding-bottom: 0.2rem">
				<item-title name="特产资源" english="Specialty resources" />
				<lineBorderBox type='bottom' />
				<div style="padding: 0 0.08rem;">
					<img-text-list style="margin-top: 0.1rem;box-sizing: border-box;" height="1.6rem" :dataList="resourcesData"></img-text-list>
				</div>
			</div>
		</div>
		<div class="page_temp page_temp_right" @click="active1 = 0">
			<div class="contentBox">
				<item-title name="村庄变迁" english="Specialty resources" />
				<lineBorderBox type='bottom' />
				<img-text-list type='specialtyResources' @showTableInfo="showTableInfo" style="margin-top: 0.1rem;box-sizing: border-box;" height="4rem" :dataList="tableData"></img-text-list>
				<!-- <planning-graph-map :tableData="tableData" @showTableInfo="showTableInfo" :clear="gh_clear" /> -->
			</div>
			<div class="contentBox" style="margin-top: 0.2rem;padding-bottom: 0.2rem;">
				<item-title name="空间规划" english="Spatial Planning" />
				<lineBorderBox type='bottom' />
				<!-- <planning-graph-map :tableData="tableDataK" @showTableInfo="showTableInfo"  :clear="gh_clear"/> -->
				<img-text-list type='spatialPlanning' @showTableInfo="showTableInfo" style="margin-top: 0.1rem;box-sizing: border-box;" height="1.8rem" :dataList="tableDataK"></img-text-list>
			</div>
		</div>
		<!-- 视频弹窗 -->
		<message-pop :option="videoOption" @clearPop="clearPop" v-if="messageActive == 3">
			<div style="width: 100%;overflow: hidden; height: 3.4rem;margin-top: .2rem;">
				<span v-html="videoSource"></span>
			</div>
		</message-pop>
		<!-- 规划变迁弹窗 -->
		<dot-pop :name="tabOption.name" :width="400" :height="300" :x="tabOption.x" :y="tabOption.y" :z="tabOption.z"
			@closePane="clearPopGhbq" v-if="messageActive == 4">
			<div
				style="display: flex;flex-direction: column;justify-content: space-between;height: 100%;box-sizing: border-box;">
				<div class="cls-pane-content scrollbar" style="flex: 1;overflow: auto;">
					<div style="margin-top: .12rem;">标注名称：&nbsp;{{ tabOption.itemName }}</div>
					项目描述:&nbsp;{{ tabOption.description }}
				</div>
				<div class="cls-pane-content" style="display: flex;justify-content: space-between;padding-top: 0.1rem;">
					<div class="cls-pane-content item">责任人：{{ tabOption.principal }}</div>
					<div class="cls-pane-content item">建设日期：{{ tabOption.buildDate }}</div>
				</div>
			</div>
		</dot-pop>

		<tooltip :title="tipTitle" :top="tipTop" :left="tipLeft" v-show="tipShow"></tooltip>
		<!-- 区位交通点位 -->
		<div v-for="(item, index) in dotData" :key="index" v-show="dotData">
			<dot-pop :name="item.name" :x="item.x" :y="item.y" :z="7" :type='`dot`' :color="dotColor">
				<!-- <img style="width: .30rem;" :src="dotIcon"> -->
			</dot-pop>
		</div>

		<map-button-bar :enableCircleFly="true" :enableClickMapJmd="true" ref="mapButtonBar"></map-button-bar>
	</div>

</template>

<script>
import http from '../util/http.js';
import {
	getLonLatArray,
	addPointWithString,
	addPoint,
	addLine,
	cleanMarkerListByType,
	getMakerById
} from '../util/drawShape.js';
import lineBorderBox from '../components/line-border-box.vue'
import itemTitle from '../components/item-title.vue'
import messagePop from '../components/message-pop.vue'
import planningGraphMap from '../components/planningGraphMap.vue'
import {
	getBasic,
	getChange,
	getIconList,
	getInformationList,
	getChangeK,
	getVideoList,
	getVrList,
	getOverView,
	getInformation
} from '@/api/tutorials.js'
import dotPop from "@/components/dot-pop.vue";
import config from '@/config.js';
import tooltip from '@/components/tooltip.vue';
import mapButtonBar from "@/components/mapButtonBar.vue";
import imgTextList from "@/components/imgTextList.vue"

var g_xqid = localStorage.getItem('tenantCode'); //config.TENANT_CODE; //'420626107204';

var g_is_active_border = false;
var g_border_points = []; //辖区边界坐标
var g_entity_polygon = null; //辖区边界线

var g_qyjt_center_data = null;
var g_qyjt_center_point = null;
var g_qyjt_buffer_list = [];
var g_qyjt_buffer_lines = [];

var g_handler = null;

import {
	mapState
} from 'vuex';
export default {
	components: {
		lineBorderBox,
		itemTitle,
		messagePop,
		dotPop,
		tooltip,
		planningGraphMap,
		mapButtonBar,
		imgTextList
	},
	watch: {
		active1: function (newVal, ondVal) {
			if (newVal != 1) {
				viewer.scene.scanEffect.show = false;
				this.removeFlyLine();
				this.v_boolean_show = true
				this.dotData = []
				if (g_is_active_border) {
					if (g_entity_polygon) {
						viewer.entities.remove(g_entity_polygon);
						g_entity_polygon = null;
						console.log('--隐藏辖区边线');
					}
				}
				g_is_active_border = false;
				this.$parent.change3DViewer();
			}
		},
		menu_active: function (newValue, oldValue) {
			if (newValue != 1) {
				viewer.scene.scanEffect.show = false;
				this.removeFlyLine();
				this.v_boolean_show = true;
				viewer.entities.remove(g_entity_polygon);
				g_entity_polygon = null;
				if (g_handler) {
					g_handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
				}
			} else {
				if (g_handler) {
					g_handler.setInputAction(this.updateTip, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
				}
			}
			if (newValue != 2) {
				cleanMarkerListByType('ghbq');
				this.messageActive = 0;
				this.active1 = 0;
			}
			if (newValue != 3) {
				cleanMarkerListByType('ghbq');
				this.messageActive = 0;
				this.active1 = 0;
			}
		}
	},
	computed: {
		// 计算属性的 getter
		videoSource: function () {
			return `<video style="width:98%;height:98%" controls  poster="${this.videoImg ? this.videoImg : ''}">
                        <source src="${this.videoList}" type="video/mp4">
                    </video>`;
		},
		...mapState(['menu_active'])
	},
	data() {
		return {
			gh_clear:true,
			kjgh_cur: -1,
			czbq_cur: -1,
			kjgh_p: -1,
			czbq_p: -1,
			// 特色资源数据
			resourcesData: [],
			// 特色资源弹窗数据
			resourcesPop: {},
			// 产业发展数据
			industryData: [],
			// 产业发展弹窗数据
			industryPop: {},
			// 领导关怀数据
			leadData: [],
			// 村庄荣誉数据
			villageData: [],
			// 村庄规划表格数据
			tableData: [],
			// 空间变迁表格数据
			tableDataK: [],
			// 特色资源弹窗配置
			resourceOption: {
				width: 400,
				height: 436,
				type: 'right',
				name: '详情'
			},
			// 旅游资源弹窗配置
			travelOption: {
				width: 400,
				height: 436,
				type: 'right',
				name: '详情'
			},
			// 视频弹窗配置
			videoOption: {
				width: 650,
				height: 440,
				type: 'center',
				name: '视频介绍'
			},
			// 规划变迁弹窗配置
			tabOption: {
				width: 400,
				height: 240,
				type: 'center',
				name: '休闲小广场',
				buildDate: '',
				itemName: '',
				description: '',
				ownInstitution: '',
				x: 0.0,
				y: 0.0,
				z: 0.0
			},
			tipTitle: '',
			tipTop: 0,
			tipLeft: 0,
			tipShow: false,

			// 视频数据
			videoList: [],
			videoImg: '',
			// 弹窗编号
			messageActive: 0,
			// 乡村概况 1 2 3 特色资源 4 5 6 产业发展 7 8 9
			active1: 0,
			active2: 0,
			v_boolean_show: true,
			v_info_list: [{
				name: '乡村面积',
				value: '--',
				unit: '平方公里',
				icon: require('@/assets/img/tutorials/w1.png')
			},
			{
				name: '可耕地面积',
				value: '--',
				unit: '亩',
				icon: require('@/assets/img/tutorials/w2.png')
			},
			{
				name: '农户数',
				value: '--',
				unit: '户',
				icon: require('@/assets/img/tutorials/w3.png')
			},
			{
				name: '人口数',
				value: '--',
				unit: '人',
				icon: require('@/assets/img/tutorials/w4.png')
			},

			{
				name: '村小组数',
				value: '--',
				unit: '个',
				icon: require('@/assets/img/tutorials/w5.png')
			},
			{
				name: '土地流转率',
				value: '--',
				unit: '',
				icon: require('@/assets/img/tutorials/w6.png')
			}
			],
			v_cun_jyjs: '', //村庄概况

			v_if_show_vr: false,
			v_list_vr: [],

			transfromLeft: '',
			transfromRight: '',
			json: {
				pageNum: 1
			},
			// 720云地址
			yunUrl: '',
			menu_name: localStorage.getItem('tenantName'),
			dotData: [],
			dotColor: '#1BCDBA',
			// dotIcon: require('../assets/marker/dot.png')

		}
	},
	mounted() {
		let pMountedTimer = window.setInterval(() => {
			if (window.parentMounted) {
				window.clearInterval(pMountedTimer);
				this.initMap();
				this.initData();
			}
		}, 500)

		// let villageName = localStorage.getItem('tenantName')
		// switch (villageName) {
		// 	case '中坪村':
		// 		this.v_info_list[0].value = 17.81;
		// 		this.v_info_list[1].value = 3670;
		// 		this.v_info_list[2].value = 1011;
		// 		this.v_info_list[3].value = 4200;
		// 		this.v_info_list[4].value = 9;
		// 		break;
		// 	case '尧治河村':
		// 		this.v_info_list[0].value = 33.4;
		// 		this.v_info_list[1].value = 700;
		// 		this.v_info_list[2].value = 160;
		// 		this.v_info_list[3].value = 720;
		// 		this.v_info_list[4].value = 2;
		// 		break;
		// 	case '黄龙观村':
		// 		this.v_info_list[0].value = 23.8;
		// 		this.v_info_list[1].value = 1610;
		// 		this.v_info_list[2].value = 225;
		// 		this.v_info_list[3].value = 906;
		// 		this.v_info_list[4].value = 5;
		// 		break;
		// }
	},
	methods: {
		initMap() {
			this.$parent.show3DMap();
		},
		// 初始化数据
		initData: function () {
			g_xqid = localStorage.getItem('tenantCode');
			this.getBaseData();
			this.getChange()
			this.getIconList()
			// this.getInformationList()
			// this.getVrList()
			this.getOverView()
			this.requestQyjt();
			this.requestVrList();

			// g_handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
			// g_handler.setInputAction(this.updateTip, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

			let json = {
				pageSize: 1,
				pageNum: 1
			}
			getVideoList(json).then(res => {
				if(res.rows.length>0){
					this.videoList = res.rows[0].videoUrl
					this.videoImg = res.rows[0].coverUrl
				}
			})
		},

		updateTip(movement) {
			let pickedObject = viewer.scene.pick(movement.endPosition); // 拾取场景中的图元
			if (pickedObject) {
				viewer.enableCursorStyle = false;
				viewer._element.style.cursor = '';
				//$('body').removeClass('handCursor').addClass('handCursor');
				this.tipShow = true;
				this.tipLeft = movement.endPosition.x;
				this.tipTop = movement.endPosition.y;
				this.tipTitle = pickedObject.id.name;
				//tooltip.showAt(movement.endPosition, pickedObject.id.name);
			} else {
				viewer.enableCursorStyle = true;
				//$('body').removeClass('handCursor');
				this.tipShow = false;
			}
		},
		getBaseData: function () {
			getBasic().then(res => {
				// 乡村概况基本信息
				let villageProfile = res.data.villageProfile
				this.v_info_list[0].value = villageProfile.totalArea
				this.v_info_list[4].value = villageProfile.villageGroup
				this.v_info_list[2].value = villageProfile.household
				this.v_info_list[3].value = villageProfile.population
				this.v_info_list[1].value = villageProfile.farmland
				this.v_info_list[5].value = villageProfile.landTransferRate

				// let i = res.data.initialFeature

				// //初始化二维位置
				// this.$parent.g_camera_position = {
				//   "x": Number(i.camera2PointX),
				//   "y": Number(i.camera2PointY),
				//   "z": parseInt(i.camera2PointZ)
				// }
				// //初始化三维位置
				// this.$parent.g_camera_3d_position = {
				//   "x": Number(i.camera3PointX),
				//   "y": Number(i.camera3PointY),
				//   "z": parseInt(i.camera3PointZ),
				//   "heading": Number(i.camera3PointHeading),
				//   "pitch": Number(i.camera3PointPitch),
				//   "roll": Number(i.camera3PointRoll)
				// }
				// this.$parent.v_list_fly = res.data.flyList;
				// this.$parent.v_map_3d_list = res.data.tiltList;
				// this.$parent.v_map_2d_yx_list = res.data.flatList;


				//边界坐标字符串
				g_border_points = res.data.initialFeature.geometry.pointArr;

			})
		},
		// 获取规划变迁列表
		getChange: function () {
			let data = {
				pageSize: 6,
				pageNum: 1
			}
			getChange(data).then(res => {
				this.tableData = res.rows
			})
			getChangeK(data).then(res => {
				console.log(res, '--')
				this.tableDataK = res.rows
			})
		},

		// 获取领导关怀-村庄荣誉数据-特色资源
		getIconList: function () {
			let data = this.json
			for (let i of [1, 2, 3]) {
				data.type = i
				if (i == 3) {
					data.pageSize = 4
				}
				getIconList(data).then(res => {
					if (i == 1) { //领导关怀
						this.leadData = res.rows
					} else if (i == 2) { //村庄荣誉
						this.villageData = res.rows
					} else if (i == 3) { //特色资源
						this.resourcesData = res.rows
					}
				})
			}
		},
		// 产业发展列表
		// getInformationList: function () {
		//     this.json.essayType = 2
		//     this.json.pageSize = 3
		//     getInformationList(this.json).then(res => {
		//         this.industryData = res.rows
		//     })
		// },
		// 获取720云数据
		// getVrList: function () {
		//     getVrList().then(res => {
		//         console.log(res, '--720云')
		//     })
		// },
		// 获取村庄概况
		getOverView: function () {
			getOverView().then(res => {
				if(res.code == 500){
					console.log('获取村庄概况', res.msg);
				}else{
					this.v_cun_jyjs = res.data.abstracts
				}
			})
		},

		//请求村区域交通信息
		requestQyjt: function () {
			var fromIndex = 0;
			var toIndex = 20;
			var _fields = ['smid', 'zxmc', 'zxx', 'zxy', 'zbmc', 'zbx', 'zby'];
			var sqlParameter = {
				"getFeatureMode": "SQL",
				"datasetNames": ["p420000:t_cun_qyjt"],
				"queryParameter": {
					attributeFilter: "sscid='" + g_xqid + "'",
					fields: _fields
				},
				"maxFeatures": 20
			}
			http.requestIServerWithPage(sqlParameter, fromIndex, toIndex, (result) => {
				console.log('--接收村区域交通信息--', result.featureCount);
				g_qyjt_center_data = {};
				g_qyjt_buffer_list = [];
				for (var i = 0; i < result.totalCount; i++) {
					var item = result.features[i];
					if (i == 0) {
						g_qyjt_center_data.zxmc = item.fieldValues[1];
						g_qyjt_center_data.zxx = parseFloat(item.fieldValues[2]);
						g_qyjt_center_data.zxy = parseFloat(item.fieldValues[3]);
					}
					var _line_data = {};
					_line_data.id = item.fieldValues[0];
					_line_data.zxmc = item.fieldValues[1];
					_line_data.zxx = parseFloat(item.fieldValues[2]);
					_line_data.zxy = parseFloat(item.fieldValues[3]);
					_line_data.zbmc = item.fieldValues[4];
					_line_data.zbx = parseFloat(item.fieldValues[5]);
					_line_data.zby = parseFloat(item.fieldValues[6]);
					g_qyjt_buffer_list.push(_line_data);
				}
			});
		},

		//显示与隐藏切换
		toggleQyjt: function () {

			this.toggleXqBorder();
			if (this.v_boolean_show) {
				this.drawScanEffect();
				this.drawFlyLine();
				this.active1 = 1
				this.messageActive = 0
			} else {
				viewer.scene.scanEffect.show = false;
				this.dotData = [];
				this.removeFlyLine();
				this.active1 = 0;
				this.$parent.change3DViewer();
			}

			this.v_boolean_show = !this.v_boolean_show;

		},
		//720云vr点位信息展示与隐藏
		toggleVrMarker: function () {
			if (this.v_list_vr.length == 0) {
				alert('未查询到720点位信息');
				return;
			}
			this.v_if_show_vr = !this.v_if_show_vr;
			if (this.v_if_show_vr) {
				for (var item of this.v_list_vr) {
					var _config = {
						image: require('../assets/marker/location.png'),
						width: 20,
						height: 25,
						url: item.url,
						z: this.$parent.getZ()
					};
					addPoint('vr', item.id, item.mc, item.shape, '', _config);
				}
				this.addClickEvent();
			} else {
				cleanMarkerListByType('vr');
				if (g_handler) {
					g_handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
				}
			}
		},
		requestVrList: function () {
			var _sqlParameter = {
				"getFeatureMode": "SQL",
				"datasetNames": ["p420000:t_cun_vr"],
				"queryParameter": {
					attributeFilter: "sscid='" + g_xqid + "'",
					fields: ["id", "mc", "url"]
				},
				"maxFeatures": 100
			}
			http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
				console.log('--720云点位回调--', result.totalCount);
				if (result.features.length > 0) {
					this.v_list_vr = [];
					for (var i = 0; i < result.features.length; i++) {
						var _feature = result.features[i];
						this.v_list_vr.push({
							id: i,
							mc: _feature.fieldValues[1],
							url: _feature.fieldValues[2],
							shape: [_feature.geometry.center.x, _feature.geometry.center.y]
						});
					}

				}
			});
		},
		addClickEvent: function () {
			//单击选中事件
			//g_handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
			g_handler.setInputAction((movement) => {
				var pick = viewer.scene.pick(movement.position);
				if (Cesium.defined(pick)) {
					if (pick.id) {
						//选中
						var _entity = pick.id;
						if (_entity.ptype != 'vr') {
							return;
						}
						var _url = _entity.data.url;
						if (_url) {
							// window.open(_item.url, '_blank');
							this.messageActive = 5
							this.yunUrl = _url;
						}
					}
				}
			}, Cesium.ScreenSpaceEventType.LEFT_CLICK);
		},

		//画中心扫描线
		drawScanEffect: function () {
			var pos = new Cesium.Cartesian3.fromDegrees(g_qyjt_center_data.zxx, g_qyjt_center_data.zxy,
				10); //(110.91047, 31.75954, 10);
			viewer.scene.scanEffect.centerPostion = pos;
			viewer.scene.scanEffect.mode = Cesium.ScanEffectMode.CIRCLE; //'ring-scan-mode';
			viewer.scene.scanEffect.speed = 3000;
			viewer.scene.scanEffect.period = 3;
			viewer.scene.scanEffect.color = Cesium.Color.fromCssColorString("#eaa841");
			viewer.scene.scanEffect.show = true;
			// console.log('--drawScanEffect--', Cesium.ScanEffectMode.CIRCLE);
		},
		//画飞航曲线
		drawFlyLine: function () {
			this.dotData = []
			for (var i = 0; i < g_qyjt_buffer_list.length; i++) {
				var _item = g_qyjt_buffer_list[i];
				var id = _item.id;
				var zxmc = _item.zxmc;
				var zxx = _item.zxx;
				var zxy = _item.zxy;
				var zbmc = _item.zbmc;
				var zbx = _item.zbx;
				var zby = _item.zby;

				let startName = zxmc;
				let destinationName = zbmc;
				let migrationNumber = 100;

				// 用于拟合当前曲线的笛卡尔坐标点数组
				let startPt = Cesium.Cartesian3.fromDegrees(zxx, zxy, 0);
				let endPt = Cesium.Cartesian3.fromDegrees(zbx, zby, 0);
				let curLinePointsArr = generateCurve(startPt, endPt);

				g_qyjt_buffer_lines.push(viewer.entities.add({ // 背景线
					description: 'background-line',
					name: startName + ' -> ' + destinationName,//+ ' ' + migrationNumber + '公里',
					polyline: {
						width: 3,
						positions: curLinePointsArr,
						material: new Cesium.PolylineDashMaterialProperty({
							color: new Cesium.Color(255 / 255, 249 / 255, 0, 0.5)
						})
					}
				}));

				g_qyjt_buffer_lines.push(viewer.entities.add({ // 尾迹线
					description: 'trail-line',
					name: startName + ' -> ' + destinationName,//+ ' ' + migrationNumber + '公里',
					polyline: {
						width: 10,
						positions: curLinePointsArr,
						material: new Cesium.PolylineTrailMaterialProperty({ // 尾迹线材质
							color: Cesium.Color.fromCssColorString("rgba(118, 233, 241, 1.0)"),
							trailLength: 0.2,
							period: 5.0
						})
					}
				}));

				g_qyjt_buffer_lines.push(viewer.entities.add({ // 终点
					description: 'end-point',
					name: destinationName,
					position: endPt,
					point: {
						color: new Cesium.Color(251 / 255, 7 / 255, 0, 0.7),
						pixelSize: 10,
						disableDepthTestDistance: Number.POSITIVE_INFINITY
					}
				}));

				//
				// g_qyjt_buffer_lines.push(viewer.entities.add({ // 终点
				// 	description: 'juli',
				// 	name: startName + destinationName,
				// 	position: endPt,
				// 	label: {
				// 		// position:
				// 		text: destinationName,
				// 		font: 'normal 24px MicroSoft YaHei',
				// 		fillColor: Cesium.Color.fromCssColorString('#000'), //Cesium.Color.BLACK,
				// 		backgroundColor: Cesium.Color.fromCssColorString('#fff'),
				// 		showBackground: true,
				// 		scale: 0.5,
				// 		pixelOffset: new Cesium.Cartesian2(0, -24)
				// 	}
				// }));
				let arr = {}
				arr.x = g_qyjt_buffer_list[i].zbx
				arr.y = g_qyjt_buffer_list[i].zby
				arr.name = g_qyjt_buffer_list[i].zbmc
				this.dotData.push(arr)
			}
			viewer.flyTo(g_qyjt_buffer_lines, {
				duration: 5,
				offset: new Cesium.HeadingPitchRange(0.64, -0.73)
			});
		},
		//删除飞航曲线
		removeFlyLine: function () {
			for (var i = 0; i < g_qyjt_buffer_lines.length; i++) {
				if(g_qyjt_buffer_lines[i]){
					viewer.entities.remove(g_qyjt_buffer_lines[i]);
				}
			}
			g_qyjt_buffer_lines = [];
		},

		//显示与隐藏辖区边界线
		toggleXqBorder: function () {
			if (g_is_active_border) {
				if (g_entity_polygon) {
					viewer.entities.remove(g_entity_polygon);
					g_entity_polygon = null;
					console.log('--隐藏辖区边线');
				}
			} else {
				if (g_border_points.length > 0) { //如果已经缓存坐标串，直接画面，不需请求服务器
					g_entity_polygon = this.drawXqBorderPolygon(g_border_points);
					// viewer.flyTo(g_entity_polygon, {
					//     duration: 5,
					//     offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0))
					// });
					console.log('--显示辖区边线', g_border_points.length);
				}
			}
			g_is_active_border = !g_is_active_border;
		},

		//画边界线
		drawXqBorderPolygon: function (pointArr) {
			return viewer.entities.add({
				polyline: {
					clampToGround: true,
					positions: Cesium.Cartesian3.fromDegreesArray(pointArr),
					material: new Cesium.Color.fromCssColorString("#0b77dd"), //.withAlpha(.2),                     
					width: 4
				}
			});
		},
		// 特色资源弹窗
		showResourcesInfo: function (index, id) {
			this.messageActive = 1
			this.active1 = index + 4
			getInformation(id).then(res => {
				this.resourcesPop = res.data
			})
		},
		// 产业发展弹窗
		// showIndustryInfo: function (index, id) {
		//     this.messageActive = 2
		//     this.active1 = index + 7
		//     getInformation(id).then(res => {
		//         this.industryPop = res.data
		//     })
		// },
		// 视频弹窗
		showVideoInfo: function () {
			this.messageActive = 3
			this.active1 = 2
			// 获取视频介绍数据
			let json = {
				pageSize: 1,
				pageNum: 1
			}
			getVideoList(json).then(res => {
				this.videoList = res.rows[0].videoUrl
				this.videoImg = res.rows[0].coverUrl
			})
		},
		// 规划变迁弹窗
		showTableInfo: function (item,index) {
			if (!item.name) { //为空的不处理
				return;
			}
			this.messageActive = 4

			// if (type == 0) {
			// 	this.active1 = index + 10
			// 	this.active2 = 0
			// } else {
			// 	this.active1 = 0
			// 	this.active2 = index + 10
			// }

			var z = item.height || 500;

			this.tabOption.name = item.name;
			this.tabOption.buildDate = item.buildDate;
			this.tabOption.description = item.description;
			this.tabOption.principal = item.principal;
			this.tabOption.itemName = item.itemName;
			let x = item.geometry.pointArr[0];
			let y = item.geometry.pointArr[1];
			this.tabOption.x = x;
			this.tabOption.y = y;
			this.tabOption.z = z;

			// viewer.flyTo(item.geometry.points[0].x,item.geometry.points[0].y);
			cleanMarkerListByType('ghbq');
			if (item.geometry.type == 'POINT') {
				addPointWithString('ghbq', index, '', item.geometry.pointArr, '', {
					width: 20,
					height: 25,
					z: z//this.$parent.getZ()
				});
			} else {
				addLine('ghbq', index, '', item.geometry.pointArr, '', {
					z: this.$parent.getZ()
				});
			}
			var hcv = {
				//将经度、纬度、高度的坐标转换为笛卡尔坐标
				destination: Cesium.Cartesian3.fromDegrees(x, y, z+500),
				orientation: {
					heading: 0.0,
					pitch: -1.5,
					range: 5000.0
				}
			}
			viewer.camera.flyTo(hcv);
		},
		// 关闭弹窗
		clearPop: function () {
			this.messageActive = 0
			this.active1 = 0
		},
		clearPopGhbq() {
			this.messageActive = 0;
			this.active1 = 0;
			this.czbq_cur = -1;
			this.kjgh_cur = -1;
			this.gh_clear = !this.gh_clear
			cleanMarkerListByType('ghbq');
		}

	},
	beforeCreate() {
        g_map_type = 3;
    },
	destroyed() {
		//销毁村区域交通信息
		viewer.scene.scanEffect.show = false;
		this.removeFlyLine();
		viewer.entities.remove(g_entity_polygon);
		g_entity_polygon = null;
		cleanMarkerListByType('vr');
		cleanMarkerListByType('ghbq');
		if (g_handler) {
			g_handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
			g_handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
		}
		g_handler = g_handler && g_handler.destroy();
		// console.log('规划组件销毁');
	}
}

/**
 * 生成曲线来表达流动曲线
 * @param startPoint 起点
 * @param endPoint 终点
 * @returns {Array}
 */
function generateCurve(startPoint, endPoint) {
	let addPointCartesian = new Cesium.Cartesian3();
	Cesium.Cartesian3.add(startPoint, endPoint, addPointCartesian);
	let midPointCartesian = new Cesium.Cartesian3();
	Cesium.Cartesian3.divideByScalar(addPointCartesian, 2, midPointCartesian);
	let midPointCartographic = Cesium.Cartographic.fromCartesian(midPointCartesian);
	midPointCartographic.height = Cesium.Cartesian3.distance(startPoint, endPoint) / 10;
	let midPoint = new Cesium.Cartesian3();
	Cesium.Ellipsoid.WGS84.cartographicToCartesian(midPointCartographic, midPoint);
	let spline = new Cesium.CatmullRomSpline({
		times: [0.0, 0.5, 1.0],
		points: [startPoint, midPoint, endPoint]
	});
	let curvePointsArr = [];
	for (let i = 0, len = 300; i < len; i++) {
		curvePointsArr.push(spline.evaluate(i / len));
	}
	return curvePointsArr;
}
</script>
<style>
.el-carousel__item h3 {
	color: #475669;
	font-size: 14px;
	opacity: 0.75;
	line-height: 1.4rem;
	margin: 0;
}

.el-carousel__indicators {
	display: none;
}

.el-carousel__indicator {
	width: 10rpx;
}

.el-carousel__item {
	border-radius: .1rem;
}

/* .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
} */

.el-table__row {
	height: .38rem;
}

.leader .el-carousel__container {
	height: 100%;
}

.cls-pane-content {
	font-size: 14px;
	color: #fff;
	line-height: 30px;
	display: flex;
	flex-direction: column;
}
</style>
<style lang="scss" scoped>
.cls-button {
	color: white;
	cursor: pointer;
}

.cls-button-name {
	height: 1.2rem;
	line-height: .28rem;
	padding: 0 .15rem;
	box-sizing: border-box;
	overflow: auto;
}

.cls-button-list {
	display: flex;
	justify-content: space-around;
	padding: .2rem .4rem .15rem;
}

.cls-button-list div {
	width: 1.16rem;
	height: .37rem;
	display: flex;
	background-image: url(../assets/img/server/b3.png);
	background-repeat: no-repeat;
	background-size: 100%;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.cls-button-list .zrfm-cur,
.cls-button-list div:hover {
	background-image: url(../assets/img/server/b4.png);
}

.info_list {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0 .15rem;
	box-sizing: border-box;

	.item {
		width: 50%;
		font-size: .14rem;
		padding: .1rem 0;

		.namebox {
			display: inline-block;
			width: .82rem;
			text-align-last: justify;
			text-align: justify;
			text-justify: distribute-all-lines;
		}

		.name {
			font-size: .16rem;
			color: #79b7f7;
			font-weight: 600;
			letter-spacing: .01rem;
		}
	}
}

.honour {
	box-sizing: border-box;
}

.leader {
	display: flex;
	justify-content: space-around;

	.item {
		width: 100%;
		height: 100%;
		cursor: pointer;

		.leader-box {
			display: flex;
			justify-content: space-between;

			.leader-item {
				width: 48%;
				height: 100%;

				.img {
					width: 100%;
					height: 1.2rem;
					border: .01rem solid #ddd;
					cursor: pointer;
					overflow: hidden;
					border-radius: .1rem;
					box-sizing: border-box;

					img {
						width: 100%;
						object-fit: cover;
					}
				}

				span {
					font-size: .14rem;
					display: -webkit-box;
					text-overflow: ellipsis;
					overflow: hidden;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					margin: .1rem 0 0 0;
				}
			}
		}


	}

	div .img:hover img {
		border-color: #79b7f7;
		transform: scale(1.1);
		-webkit-transform: scale(1.1);
	}
}

.resources {
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	flex-wrap: wrap;
	padding: 0 .15rem;

	.item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin-bottom: .2rem;

		.img {
			width: 1.6rem;
			height: .9rem;
			border: solid .01rem #dddddd;
			border-radius: .1rem;
			overflow: hidden;
		}

		span {
			font-size: .14rem;
			margin-top: .1rem;
		}
	}

	.item:hover .img {
		border-color: #79b7f7;
	}

	.item:hover span {
		color: #79b7f7;
	}
}

.resourcesCur {
	.img {
		border-color: #79b7f7 !important;
	}

	span {
		color: #79b7f7;
	}
}

// 产业发展
.industry {

	// .list{
	//     display: flex;
	//     flex-direction: column;
	// }
	.item {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: .9rem;
		cursor: pointer;

		.img {
			width: 36%;
			height: 100%;
			box-shadow: 0px 0px .1rem .01rem rgba(228, 255, 254, 0.3);
			border-radius: .1rem;
			border: solid .01rem #ddd;
			overflow: hidden;
		}

		div {
			width: 58%;
			height: 100%;

			span {
				font-weight: normal;
				font-stretch: normal;
				line-height: .3rem;
				letter-spacing: 0rem;
				color: #ffffff;
			}

			p {
				font-size: .14rem;
				font-weight: normal;
				font-stretch: normal;
				line-height: .24rem;
				letter-spacing: 0px;
				color: #ffffff;

				display: -webkit-box;
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
	}

	.item:hover .img {
		box-shadow: 0px 0px 10px 1px rgba(228, 255, 254, 0.3);
		border: solid 1px #79b7f7;
	}

	.item:hover span {
		color: #79b7f7;
	}

	.item:nth-child(3) {
		margin: .14rem 0;
	}
}

.page_temp .industry .industryCur {
	.img {
		box-shadow: 0px 0px 10px 1px rgba(228, 255, 254, 0.3);
		border: solid 1px #79b7f7;
	}

	span {
		color: #79b7f7;
	}
}

// 规划变迁
.project {
	height: 7.6rem;
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: auto;

	table {
		width: 100%;
		border-collapse: collapse;
		font-size: .14rem;

		tr {
			height: .32rem;
			font-size: .14rem;

			td {
				text-align: center;
				font-size: .14rem;
			}
		}
	}

	table>tr:first-child {
		height: .38rem;
	}
}

// 特色资源弹窗
.resource-pop {
	top: .56rem;
}

.travel-pop {
	top: 2.74rem;
}

.table-item:hover {
	color: #79b7f7;
	cursor: pointer;
}

.tabCur {
	color: #79b7f7;
}

// @media only screen and (min-width:1920px) {
//     .resource-pop {
//         top: 64px;
//     }

//     .travel-pop {
//         top: 324px;
//     }
// }
.yun {
	position: absolute;
	z-index: 999;
	width: calc(100vw - 8.2rem);
	height: calc(100% - .96rem);
	top: .6rem;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: #747474;

	display: flex;
	flex-direction: column;

	.title {
		display: flex;
		justify-content: space-between;
		padding: 0.1rem 0.14rem;
		box-sizing: border-box;
		font-size: 0.14rem;
		color: #fff;

		i {
			font-size: 0.2rem;
			cursor: pointer;
		}
	}

	.yun-content {
		width: 100%;
		height: 100%;
	}
}

.project-item {
	.time {
		span {
			color: #ffc748;
			font-size: .18rem;
			line-height: .18rem;
		}

		span:first-child {
			display: inline-block;
			width: .1rem;
			height: .1rem;
			background-color: #ffc748;
			border-radius: 50%;
			margin-right: .2rem;
		}

		transform: translateX(-0.04rem);
	}

	.project-content {
		border-left: .02rem dashed#ffc748;
		padding: .1rem .16rem;
		box-sizing: border-box;

		ul {
			flex: 1;
		}

		li {
			list-style-type: none;
			margin-bottom: .2rem;
			margin-left: .3rem;
			cursor: pointer;
		}

		li:hover {
			color: #79b7f7;
		}

		display: flex;
	}
}

.gh_cur {
	color: #79b7f7;
}

.img-item {
	display: flex;
	align-items: center;
	width: 100%;
	background-color: rgba(225, 225, 225, 0.3);
	height: .68rem;
	border-radius: .04rem;
	margin-top: .15rem;
	box-sizing: border-box;
	overflow: hidden;
	padding-left: .24rem;

	img {
		margin-right: .16rem;
		width: .38rem;
		height: .38rem;
		border-radius: 50%;
	}

	div {
		font-size: .14rem;

		span {
			font-size: .16rem;
			margin: 0 .16rem;
			color: #fff000;
		}
	}
}

.img-item-first {
	margin-top: .25rem;
}

.img-box {
	width: 100%;
	display: flex;
	justify-content: space-between;

	.img-item {
		width: 48%;

		div {
			display: flex;
			flex-direction: column;

			div {
				display: flex;
				flex-direction: row;

				span {
					margin-left: 0;
				}
			}
		}
	}
}

.img-item-div {
	display: flex;
}

.img-item-div>p:first-child {
	display: inline-block;
	width: .8rem;
}

.module-box {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;

	.module-item {
		width: 30%;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		cursor: pointer;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.name {
			margin: .06rem 0;
		}

		.title {
			font-size: .14rem;
			color: #fff;
			width: 100%;
			text-align: center;
			height: .34rem;
			position: relative;

			.img {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				background-repeat: no-repeat;
				background-size: 100%;
				background-image: url(../assets/img/server/b5.png);
			}

			span {
				font-size: .24rem;
			}
		}
	}

	.module-item:nth-child(-n+3) {
		margin-bottom: .1rem;
		.title {
			.img {
				background-image: url(../assets/img/server/b6.png);
			}
		}
	}

	// .module-item:hover {
	// 	.title {
	// 		color: #fff;

	// 		span {
	// 			color: #22f2ff;
	// 		}
	// 	}
	// }
}

.villager-item-title {
	margin: .1rem 0 0 .2rem;
	font-size: .18rem;
	font-stretch: normal;
	line-height: .2rem;
	letter-spacing: 0px;
	color: #53b9ff;
	display: flex;
	align-items: center;
	font-style: italic;

	.span {
		display: flex;
		border-radius: 50%;
		width: 0;
		height: 0;
		box-shadow: 0 0 .1rem .04rem #fff;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: .1rem;

		span {
			position: absolute;
			display: flex;
			transform: translateX(.02rem);
			width: .1rem;
			height: .1rem;
			background-color: #fff;
			clip-path: polygon(0 0, 50% 50%, 0 100%);
		}
	}
}

.content_img_item {
	width: 48%;
	height: 1.45rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: .2rem;
	cursor: pointer;

	div {
		width: 100%;
		height: 1.2rem;
		background-color: #ffffff;
		box-shadow: 0px 0px 10px 1px rgba(228, 255, 254, 0.3);
		border-radius: .04rem;
		border: solid 1px #fff;
	}

	div:hover {
		border: solid 1px #ffc748;
	}

	span {
		font-size: .14rem;
		color: #fff;
		line-height: .3rem;
	}
}

.line_between {
	display: flex;
	align-items: flex-start;
	width: 100%;
	height: .02rem;
	align-items: center;

	img {
		width: 100%;
	}

	margin: .1rem 0;
}

.info_title{
	display: flex;
	align-items: center;
	.info_dot{
		width: 0.12rem;
		height: 0.12rem;
		margin: 0.1rem 0;
		border: 0.01rem solid #069999;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		span{
			display: block;
			width: 0.06rem;
			height: 0.06rem;
			box-shadow: 0 0 0.03rem 0.01rem #18F5F5;
			background-color: #18F5F5;
		}
	}
}

.contentBox {
	box-sizing: border-box;
	padding-bottom: 0.2rem;
	.img{
		position: relative;
		width: 100%;
		height: 100%;
		
		span{
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0.04rem;
		}
	}
}
.info_title>span{
	font-size: 0.14rem;
	font-weight: 500;
	color: #00FFFF;
	line-height: 0.2rem;
	margin-left: 0.1rem;
}
.cls-button-name {
    line-height: .24rem;
    box-sizing: border-box;
    overflow: auto;
	font-size: 0.14rem;
	text-indent: 2em;
	color:#fff;
}
.dataList-item{
	width: 49%;
	height: 0.48rem;
	background: rgba(3,32,77,0.16);
	border: 0.01rem solid;
	margin-top: 0.1rem;
	border-image: linear-gradient(90deg, rgba(24, 142, 245, 1), rgba(24, 142, 245, 0)) 1 1;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	img{
		margin: 0 0.08rem;
		width: 0.4rem;
		height: 0.4rem;
		box-sizing: border-box;
	}
	.item-info{
		display: flex;
		flex-direction: column;
		font-size: 0.14rem;
		width: 100%;
		.type{
			background: linear-gradient(90deg, rgba(25,152,255,0.2) 0%, rgba(24,142,245,0) 100%);
			font-size: 0.14rem;
			font-family: Source Han Sans CN-Medium, Source Han Sans CN;
			font-weight: 500;
			color: #C4DEF5;
			line-height: 0.18rem;
			padding-left: 0.08rem;
		}
		div{
			display: flex;
			span:first-child{
				width: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 0.2rem;
				font-weight: bold;
				color: #19F5F5;
				line-height: 0.2rem;
				text-shadow: 0px 0px 0.08rem #3385FF;
			}
			span:last-child{
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;
				font-size: 0.1rem;
				width: 50%;
				transform: scale(0.8);
				font-family: Source Han Sans CN-Medium, Source Han Sans CN;
				font-weight: 500;
				color: rgba(255,255,255,0.6);
				line-height: 0.12rem;
			}
		}
	}
}
.dataList-box{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
</style>
